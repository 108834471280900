import { useState } from 'react';
import * as XLSX from 'xlsx';
import { AppBar, Button, CircularProgress, Grid, Input, Toolbar, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Download, TextSnippet } from '@mui/icons-material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { generatePassword, getDeleteSql, getInsertSql, useDataGridProMissingLicenceWarningRemover } from '../utils/utils';
import ModalDialog from './ModalDialog';
import '../styles.css';
import { v4 as uuidv4 } from 'uuid';
import { DELETE_SQL_TYPE, EXCEL_COLUMNS, INSERT_SQL_TYPE } from '../utils/constants';

const PasswordCellRenderer = ({ value, onGeneratePassword }) => {
  const handleGeneratePassword = () => {
    onGeneratePassword();
  };

  return (
    <div>
      {!value ? (
        <Button sx={{ px: 1 }} size='small' variant="contained" color='success' onClick={handleGeneratePassword}>Generate Password</Button>
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
};

const GpLoginIdCellRenderer = ({ value, onGenerateGpLoginId }) => {
  const handleGenerateGpLoginId = () => {
    onGenerateGpLoginId();
  };

  return (
    <div>
      {!value ? (
        <Button sx={{ px: 1 }} size='small' variant="contained" color='success' onClick={handleGenerateGpLoginId}>Generate ID</Button>
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
};

const ExcelDataGrid = () => {
  const ref = useDataGridProMissingLicenceWarningRemover();
  const [ excelData, setExcelData ] = useState([]);
  const [ columns, setColumns ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ fileName, setFileName ] = useState("");
  const [ selectedRows, setSelectedRows ] = useState([]);
  const [ dialogContent, setDialogContent ] = useState("");
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ dialogType, setDialogType ] = useState("");

  const getSelectedRowCount = () => {
    return selectedRows.length;
  };

  const handleGenerateInsertSqls = () => {
    let inserts = selectedRows.map(function(row) {
      let correctedRow = row - 1;
      return getInsertSql(excelData[correctedRow]);
    });
    const sqlPrefixCommand = "SET DEFINE OFF;\n";
    setDialogContent(sqlPrefixCommand + inserts.join("\n"));
    setDialogType(INSERT_SQL_TYPE);
    setOpenDialog(true);
  }

  const handleGenerateDeleteSqls = () => {
    let deletes = selectedRows.map(function(row) {
      let correctedRow = row - 1;
      return getDeleteSql(excelData[correctedRow]);
    });
    setDialogContent(deletes.join("\n"));
    setDialogType(DELETE_SQL_TYPE);
    setOpenDialog(true);
  }

  const handleFileUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if(!file) {
      setLoading(false);
      return;
    }
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if(data.length === 0) {
        setLoading(false);
        return;
      }
      const columnHeaders = data[0].map((header, index) => ({
        field: header || `column_${index}`,
        headerName: header || `Column ${index + 1}`,
        autoWidth: true,
      }));
      const rowData = data.slice(1)
        .filter(row => row.some(cell => !!cell))
        .map((row, rowIndex) => {
          const rowDataObj = {};
          row.forEach((cell, cellIndex) => {
            if(columnHeaders[cellIndex]) {
              rowDataObj[columnHeaders[cellIndex].field] = cell;
            }
          });
          rowDataObj.id = rowIndex + 1;
          return rowDataObj;
        });
      setColumns(columnHeaders);
      setExcelData(rowData);
      setLoading(false);
    };
    reader.readAsBinaryString(file);
  };

  const handleGenerateGpLoginId = (rowId) => {
    const updatedData = excelData.map((row) => {
      if(row.id === rowId) {
        row.GP_LOGINID = uuidv4();
      }
      return row;
    });
    setExcelData(updatedData);
  };

  const handleGeneratePassword = (rowId) => {
    const updatedData = excelData.map((row) => {
      if(row.id === rowId) {
        const generatedPassword = generatePassword(10);
        row.PASSWORD = generatedPassword;
      }
      return row;
    });
    setExcelData(updatedData);
  };

  const columnsWithEditableCells = columns.map((column) => {
    if(column.field === 'GP_LOGINID') {
      return {
        ...column,
        renderCell: (params) => (
          <GpLoginIdCellRenderer
            value={params.value}
            onGenerateGpLoginId={() => handleGenerateGpLoginId(params.row.id)}
          />
        ),
      };
    }
    if(column.field === 'PASSWORD') {
      return {
        ...column,
        renderCell: (params) => (
          <PasswordCellRenderer
            value={params.value}
            onGeneratePassword={() => handleGeneratePassword(params.row.id)}
          />
        ),
      };
    }
    return column;
  });

  const isRowSelectable = (params) => {
    const requiredColumnsPresent = EXCEL_COLUMNS.every((column) =>
      Object.keys(params.row).includes(column)
    );
    return requiredColumnsPresent;
  };

  return (
    <>
      {loading && <div style={{ width: '100%', height: '100%', position: 'fixed', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 9999 }}><CircularProgress size={200} style={{ position: 'relative', top: '45%', left: '45%' }}/></div>}
      <AppBar position="fixed" color='warning'>
        <Toolbar>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <TextSnippet sx={{ mr: 1 }} />
              <Typography variant='h6'>{fileName}</Typography>
            </Grid>
            <Grid item>
              <Input
                id="file-input"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
              <label htmlFor="file-input">
                <Button variant="contained" size="small" component="span">
                  <CloudUploadIcon sx={{ mr: 0.5 }} /> Upload XLSX
                </Button>
              </label>
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" color="success" onClick={() => handleGenerateInsertSqls()} disabled={getSelectedRowCount() === 0}>
                Generate Insert SQLs
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" color="error" onClick={() => handleGenerateDeleteSqls()} disabled={getSelectedRowCount() === 0}>
                Generate Delete SQLs
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" href={`${process.env.PUBLIC_URL}/example/YYYY_MM_DD_GP_LOGINS_PROD_example.xlsx`}>
                <Download sx={{ mr: 0.5 }} /> XLSX Example
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center" alignItems="top" style={{ height: '100vh' }}>
        <Grid item xs={12} sx={{ mt: 9, height: '79%' }}>
          {fileName !== "" && (
            <DataGridPro
              slots={{ toolbar: GridToolbar }}
              ref={ref}
              rows={excelData}
              columns={columnsWithEditableCells}
              checkboxSelection
              disableRowSelectionOnClick={true}
              density="compact"
              rowSelectionModel={selectedRows}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
              isRowSelectable={isRowSelectable}
            />
          )}
        </Grid>
      </Grid>
      <ModalDialog content={dialogContent} open={openDialog} setOpen={setOpenDialog} dialogType={dialogType} />
    </>
  );
};

export default ExcelDataGrid;