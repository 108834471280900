import { sha256 } from "js-sha256";
import { useEffect, useRef } from "react";
import { GP_LOGIN_NEW_COLS, GP_LOGIN_NEW_TABLE, GP_LOGIN_REF_COLS, GP_LOGIN_REF_TABLE } from "./constants";

export function useDataGridProMissingLicenceWarningRemover() {
  const ref = useRef(null);

  useEffect(() => {
    let tid;

    tid = window.setInterval(() => {
      const ele = ref.current?.querySelector('div[style*="absolute"]');

      if(ele) {
        ele.remove();

        window.clearInterval(tid);

        tid = undefined;
      }
    }, 10);

    return () => {
      if(tid) {
        window.clearInterval(tid);
      }
    };
  }, []);

  return ref;
}

export function getInsertSql(data) {
  if(!data) {
    return "--";
  }

  // GP LOGIN NEW
  const glnColumns = GP_LOGIN_NEW_COLS.map(col => col.column).join(",");
  const glnValues = GP_LOGIN_NEW_COLS.map(col => {
    const value = data[col.excelColumn];
    if(col.excelColumn === 'PASSWORD') {
      return `'${sha256(value)}'`;
    }
    return `'${value}'`;
  }).join(",");

  // GP LOGIN REF
  const glrColumns = GP_LOGIN_REF_COLS.map(col => col.column).join(",");
  const glrValues = GP_LOGIN_REF_COLS.map(col => {
    const value = data[col.excelColumn];
    return `'${value}'`;
  }).join(",");

  return (
    `INSERT INTO ${GP_LOGIN_NEW_TABLE} (${glnColumns}) VALUES (${glnValues}); --${data.PASSWORD}\n`+
    `INSERT INTO ${GP_LOGIN_REF_TABLE} (${glrColumns}) VALUES (${glrValues});`
  );
}

export function getDeleteSql(data) {
  if(!data) {
    return "--";
  }

  return (
    `DELETE FROM ${GP_LOGIN_NEW_TABLE} WHERE GP_LOGIN_NEW_D = '${data.GP_LOGINID}'; --${data.LOGIN}\n` +
    `DELETE FROM ${GP_LOGIN_REF_TABLE} WHERE GP_LOGIN_NEW_ID = '${data.GP_LOGINID}' AND OGSID = '${data.OGSID}';\n`
  );
}

export function generatePassword(length) {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';

  for(let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
}