import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { EXPORT_SQL_TYPES } from "../utils/constants";
export default function ModalDialog({content, open, setOpen, dialogType}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xl'
      >
        <DialogTitle id="alert-dialog-title">
          Generated SQLs
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line' }}>
            <Box sx={{ padding: 2, border: 1, borderColor: 'primary.main', backgroundColor: dialogType ? EXPORT_SQL_TYPES[dialogType].bgColor : '', color: 'primary.contrastText' }} elevation={0}>
              {content}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Alert severity="warning" sx={{ py:0 }}>
            Don&apos;t copy&paste&run without thinking. Sometimes you don&apos;t need to use all the generated SQLs.
          </Alert>
          <Button
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(content);
            }}
          >
            Copy SQL to clipboard
          </Button>
          <Button variant="outlined" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}