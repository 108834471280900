export const EXCEL_COLUMNS = [
  "BEZIRKID",
  "BEZIRKNAME",
  "FIRST_NAME",
  "GP_LOGINID",
  "LAST_NAME",
  "LOGIN",
  "OGSID",
  "OGSNAME",
  "OGS_TYPE",
  "PASSWORD"
];

export const GP_LOGIN_NEW_TABLE = "GP_LOGIN_NEW";
export const GP_LOGIN_NEW_COLS = [{
  column: "GP_LOGIN_NEWID",
  excelColumn: "GP_LOGINID"
}, {
  column: "LOGIN",
  excelColumn: "LOGIN"
}, {
  column: "FIRST_NAME",
  excelColumn: "FIRST_NAME"
}, {
  column: "LAST_NAME",
  excelColumn: "LAST_NAME"
}, {
  column: "PASSWORD",
  excelColumn: "PASSWORD"
}];

export const GP_LOGIN_REF_TABLE = "GP_LOGIN_REF";
export const GP_LOGIN_REF_COLS = [{
  column: "GP_LOGIN_NEW_ID",
  excelColumn: "GP_LOGINID"
}, {
  column: "OGSID",
  excelColumn: "OGSID"
}, {
  column: "OGSNAME",
  excelColumn: "OGSNAME"
}, {
  column: "OGS_TYPE",
  excelColumn: "OGS_TYPE"
}, {
  column: "BEZIRKID",
  excelColumn: "BEZIRKID"
}, {
  column: "BEZIRKNAME",
  excelColumn: "BEZIRKNAME"
}];

export const INSERT_SQL_TYPE = "INSERT";
export const DELETE_SQL_TYPE = "DELETE";
export const EXPORT_SQL_TYPES = {
  [INSERT_SQL_TYPE]: {
    bgColor: "success.dark",
  },
  [DELETE_SQL_TYPE]: {
    bgColor: "error.dark",
  }
};